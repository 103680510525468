function Astrophotography() {
    return(
        <div style={{textAlign:"center",margin:"0 auto"}}>
            <p>Coming Soon!</p>
            <p>Background Image Credit:&nbsp;
                <a style={{color: '#FFF'}} href="https://webbtelescope.org/contents/media/images/2023/128/01H449193V5Q4Q6GFBKXAZ3S03?news=true" target="_blank" rel="noreferrer">Webb Space Telescope</a>
            </p>
        </div>
    )
}

export default Astrophotography;
