import * as React from 'react'

function Portfolio () {
    return(
        <div style={{textAlign:"center",margin:"0 auto"}}>
            <p>Coming Soon!</p>
            <p>Portfolio featuring solutions and architectures.</p>
        </div>
    )

}

export default Portfolio
