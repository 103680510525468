function Labs() {
    return(
        <div style={{textAlign:"center",margin:"0 auto"}}>
            <p>Coming Soon!</p>
            <p>Useful labs to help pass certification exams that I have taken.</p>
        </div>
    )
}

export default Labs;
